import { colors } from '@/styles/GlobalStyle';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  margin-top: 58px;
  margin-bottom: 82px;

  h2 {
    font-size: 50px;
    font-weight: 700;
    line-height: 57px;
  }

  p {
    margin-top: 25px;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    max-width: 584px;
  }

  a {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    max-width: 262px;
    gap: 10px;
    background: ${colors.purple400};
    margin-top: 27px;
    border-radius: 12px;
    cursor: pointer;
    transition: 0.1s linear;

    @media (max-width: 650px) {
      max-width: initial;
    }

    :hover {
      background: #5000a2;
    }
  }

  @media (max-width: 750px) {
    margin: 35px 0;
  }
`;
