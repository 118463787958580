import Image from 'next/image';
import Link from 'next/link';

import { Wrapper, Container, Left, Right, SectionWrapper } from './styles';

import AplicativosImage from '@/images/Pages/Index/aplicativos-section.png';

import { HiOutlineArrowRight } from 'react-icons/hi';

const Aplicativos: React.FC = () => {
  return (
    <Wrapper>
      <Container>
        <SectionWrapper>
          <Left>
            <div>
              <img src="/icons/aplicativos-icon.svg" alt="Ícone de um robô" />
              <span>APLICATIVOS</span>
            </div>
            <h2>Sua transportadora não pode ficar para trás!</h2>
            <p>
              Criamos aplicações modernas e integradas ao seu sistema,
              visualmente conectadas à sua marca. Seu cliente vai agradecer :)
            </p>
            <Link href="/chatbots">
              <a>
                Saiba tudo sobre nossos apps <HiOutlineArrowRight size={24} />
              </a>
            </Link>
          </Left>
        </SectionWrapper>
        <Right>
          <Image
            src={AplicativosImage}
            quality={100}
            alt="Imagem com vários aplicativos feitos pela Hyerdev para a transportadora Delps"
            placeholder="blur"
          />
        </Right>
      </Container>
    </Wrapper>
  );
};

export default Aplicativos;
