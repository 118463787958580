import { colors } from '@/styles/GlobalStyle';
import styled, { keyframes } from 'styled-components';

const offsettozero = (maxRadius: number) => keyframes`
  to {
    stroke-dashoffset:${maxRadius};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    font-size: 12px;
    line-height: 27px;
    font-weight: 500;
  }
`;

export const CircleContainer = styled.div<{
  maxRadius: number;
  duration: number;
  delay: number;
}>`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  svg {
    transform: rotate(-90deg);
    stroke-dasharray: 201; /* (2PI * 32px (radius)) */
    stroke-dashoffset: 201; /* (2PI * 32px (radius)) */
    animation: ${({ maxRadius }) => offsettozero(maxRadius)}
      ${({ duration }) => duration}s linear forwards;
    animation-delay: ${({ delay }) => delay}s;
  }

  span {
    position: absolute;
    font-size: 21px;
    line-height: 32px;
    font-weight: 500;
    color: ${colors.green};
  }
`;
