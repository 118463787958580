import Image from 'next/image';
import Link from 'next/link';

import { Wrapper, Container, Left, Right, SectionWrapper } from './styles';

import ChatBotImage from '@/images/Pages/Index/chatbot-section.png';

import { HiOutlineArrowRight } from 'react-icons/hi';

const Chatbot: React.FC = () => {
  return (
    <Wrapper>
      <Container>
        <SectionWrapper>
          <Left>
            <div>
              <img src="/icons/chatbot-icon.svg" alt="Ícone de um robô" />
              <span>Chatbot</span>
            </div>
            <h2>Atendimento 24/7</h2>
            <p>
              O assistente virtual (bot) consulta o sistema e responde
              rapidamente às requisições dos seus clientes! Sem a necessidade de
              um atendente humano na maioria dos chamados.
            </p>
            <Link href="/chatbots">
              <a>
                Saiba tudo sobre chatbots <HiOutlineArrowRight size={24} />
              </a>
            </Link>
          </Left>
        </SectionWrapper>
        <Right>
          <Image
            src={ChatBotImage}
            quality={100}
            alt="Imagem de um celular mostrando conversas com o chatbot"
            placeholder="blur"
          />
        </Right>
      </Container>
    </Wrapper>
  );
};

export default Chatbot;
