import styled from 'styled-components';

export const PerformanceContainer = styled.div`
  width: 100%;
  max-width: 100vw;
  position: absolute;
  bottom: 0;

  display: flex;
  justify-content: center;

  height: 180px;

  transition: background 0.15s linear;

  @media (max-width: 650px) {
    height: initial;
    bottom: 30px;
  }

  @media (max-width: 450px) {
    background: #150d22;
  }
`;

export const CirclesContainer = styled.div`
  width: 100%;
  max-width: 790px;

  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;

  @media (max-width: 550px) {
    gap: 20px;
    margin-bottom: -10px;
  }

  @media (max-width: 500px) {
    margin-bottom: -20px;
  }

  @media (max-width: 450px) {
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 240px;
    column-gap: 25px;
  }
`;
