import { Container } from './styles';

import { FaWhatsapp } from 'react-icons/fa';
import { useSettings } from '@/components/global/Providers/Footer';

const BottomSection: React.FC = () => {
  const { settings } = useSettings();

  return (
    <Container>
      <h2>Pronto para inovar?</h2>
      <p>
        Nossos especialistas vão te atender sem compromisso. Vamos entender as
        necessidades e expectativas da sua empresa para traçar os próximos
        passos em direção à modernização da sua transportadora!
      </p>

      <a href={settings.whatsapp} target="_blank" rel="noreferrer noopener">
        Fale com um especialista <FaWhatsapp size={22} />
      </a>
    </Container>
  );
};

export default BottomSection;
