import styled, { ThemeProvider } from 'styled-components';

import Header from '@/components/global/Header';
import Footer from '../Footer';

import { useTheme } from '@/components/global/Providers/Theme';

import GlobalStyle, { lightTheme, darkTheme } from '@/styles/GlobalStyle';

import MobileHeaderProvider from '@/components/global/Providers/MobileHeader';

const Layout: React.FC = ({ children }): JSX.Element => {
  const { currentTheme } = useTheme();

  return (
    <ThemeProvider
      theme={currentTheme === 'light' ? lightTheme : (darkTheme as any)}
    >
      <GlobalStyle />

      <MobileHeaderProvider>
        <Header />
      </MobileHeaderProvider>

      <MainContainer>{children}</MainContainer>
      <Footer />
    </ThemeProvider>
  );
};

const MainContainer = styled.main`
  padding-top: 64px;
`;

export default Layout;
