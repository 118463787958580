import Link from 'next/link';

import {
  BurgerMenuContainer,
  MenuMobileContainer,
  MenuWrapper,
} from './styles';

import { ImWhatsapp } from 'react-icons/im';

import { useHeaderMobile } from '@/components/global/Providers/MobileHeader';
import { useSettings } from '../../Providers/Footer';

const MenuMobile: React.FC = () => {
  const { isOpen, setOpen } = useHeaderMobile();

  return (
    <MenuMobileContainer>
      <BurgerMenuContainer
        className={isOpen ? 'open' : ''}
        onClick={() => setOpen(!isOpen)}
      >
        <span />
        <span />
        <span />
        <span />
      </BurgerMenuContainer>
      {isOpen && <Menu />}
    </MenuMobileContainer>
  );
};

export default MenuMobile;

const Menu: React.FC = () => {
  const { settings } = useSettings();

  return (
    <MenuWrapper>
      <Link href="/chatbots" passHref>
        <a>Chatbots</a>
      </Link>
      <hr />
      <Link href="/notificacoes" passHref>
        <a>Notificações</a>
      </Link>
      <hr />
      <Link href="/aplicativos" passHref>
        <a>Aplicativos</a>
      </Link>
      <hr />
      <Link href="/sites" passHref>
        <a>Sites</a>
      </Link>
      <hr />
      <Link href="/a-empresa" passHref>
        <a>A empresa</a>
      </Link>
      <hr />
      <a
        href={settings.whatsapp}
        target="_blank"
        rel="noreferrer noopener"
        className="button"
      >
        Fale com a gente <ImWhatsapp size={24} />
      </a>
    </MenuWrapper>
  );
};
