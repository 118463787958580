import Image from 'next/image';
import SwiperCore, { Autoplay } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

import { Container, TransportadorasContainer } from './styles';

import { useTheme } from '@/components/global/Providers/Theme';

interface IProps {
  title?: string;
  empresas: IEmpresa[];
}

type IEmpresa = {
  src: StaticImageData | string;
  nome: string;
};

SwiperCore.use([Autoplay]);

const Transportadoras: React.FC<IProps> = ({ title, empresas }) => {
  const { currentTheme } = useTheme();
  if (!empresas?.length) return null;

  const autoPlay = {
    delay: 2000,
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
  };

  return (
    <Container style={currentTheme === 'light' ? {} : { filter: 'invert(1)' }}>
      {!!title && <h2>{title}</h2>}
      <TransportadorasContainer>
        <Swiper
          spaceBetween={23}
          slidesPerView={'auto'}
          autoplay={autoPlay}
          loop
        >
          {empresas.map((empresa) => (
            <SwiperSlide className="empresa" key={empresa.nome}>
              <Image
                src={empresa.src}
                alt={`Logo da transportadora ${empresa.nome}`}
                quality={100}
                width={160}
                height={96}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </TransportadorasContainer>
    </Container>
  );
};

export default Transportadoras;
