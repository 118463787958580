import { colors } from '@/styles/GlobalStyle';
import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  max-width: 100vw;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 32px;
  padding-bottom: 12px;

  border-top: 1px solid rgba(0, 0, 0, 0.08);
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);

  h2 {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: ${colors.gray500};
    margin-bottom: 10px;
    text-transform: uppercase;
    text-align: center;

    @media (max-width: 1088px) {
      padding: 0 30px;
    }

    @media (max-width: 500px) {
      padding: 0 20px;
    }
  }

  background: white;
`;

export const TransportadorasContainer = styled.div`
  width: 100%;
  max-width: 1024px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 10px;

  .empresa {
    width: 160px;
  }
`;
