import { useMemo } from 'react';

import SEO from '@/components/global/SEO';
import Layout from '@/components/global/Layout';

import HeroSection from '@/components/Pages/Index/HeroSection';
import Transportadoras from '@/components/global/Transportadoras';
import Chatbot from '@/components/Pages/Index/ChatbotSection';
import Notificacoes from '@/components/Pages/Index/NotificacoesSection';
import Aplicativos from '@/components/Pages/Index/AplicativosSection';
import Sites from '@/components/Pages/Index/SitesSection';
import Especialidade from '@/components/Pages/Index/EspecialidadeSection';
import BottomSection from '@/components/Pages/Index/BottomSection';

import {
  PageWrapper,
  PageContainer,
  SectionWrapper,
  IndexEmpresasWrapper,
} from '@/styles/Page';
import { GetStaticProps } from 'next';
import { ManyItems } from '@directus/sdk';
import DirectusInstance from '@/services/directusCMS';
import { CMS_URL } from '@/config';

interface IProps {
  clientes: IClients[];
}

interface IClients {
  name: string;
  logo: string;
  id: number;
}

const IndexPage: React.FC<IProps> = ({ clientes }): JSX.Element => {
  const empresas = useMemo(() => {
    return clientes.map((cliente) => {
      return {
        src: `${CMS_URL}/assets/${cliente.logo}`,
        nome: cliente.name,
      };
    });
  }, [clientes]);

  return (
    <>
      <SEO
        title="Página inicial"
        description="Soluções inteligentes para transportadoras"
      />
      <Layout>
        <PageWrapper>
          <PageContainer>
            <SectionWrapper>
              <HeroSection />
            </SectionWrapper>
          </PageContainer>
          <IndexEmpresasWrapper>
            <Transportadoras
              title="As principais empresas do mercado confiam em nosso trabalho"
              empresas={empresas}
            />
          </IndexEmpresasWrapper>
          <Chatbot />
          <Notificacoes />
          <Aplicativos />
          <Sites />
          <Especialidade />
          <PageContainer>
            <SectionWrapper>
              <BottomSection />
            </SectionWrapper>
          </PageContainer>
        </PageWrapper>
      </Layout>
    </>
  );
};

export default IndexPage;

export const getStaticProps: GetStaticProps = async () => {
  const directus = await DirectusInstance.getInstance();

  const { data: clientes }: ManyItems<IClients> = await directus
    .items('clients')
    .readByQuery({
      limit: -1,
      filter: {
        status: { _in: ['published'] },
        is_active: { _eq: true },
      },
      fields: ['logo', 'id', 'name'],
      sort: ['name' as never],
    });

  return {
    props: { clientes },
    revalidate: 100000,
  };
};
