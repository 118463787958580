import Image from 'next/image';
import Link from 'next/link';

import { Wrapper, Container, Left, Right, SectionWrapper } from './styles';

import NotificacoesImage from '@/images/Pages/Index/notificacoes-section.png';

import { HiOutlineArrowRight } from 'react-icons/hi';

const Notificacoes: React.FC = () => {
  return (
    <Wrapper>
      <Container>
        <SectionWrapper>
          <Left>
            <div>
              <img src="/icons/notificacoes-icon.svg" alt="Ícone de um robô" />
              <span>Notificações</span>
            </div>
            <h2>Seu cliente sempre atualizado!</h2>
            <p>
              Com as notificações via WhatsApp o seu processo se torna mais
              eficiênte e o seu cliente menos ansioso.
            </p>
            <Link href="/chatbots">
              <a>
                Conheças as Notificações <HiOutlineArrowRight size={24} />
              </a>
            </Link>
          </Left>
        </SectionWrapper>
        <Right>
          <Image
            src={NotificacoesImage}
            quality={100}
            alt="Imagem de um celular recebendo notificações da Hyerdev"
            placeholder="blur"
          />
        </Right>
      </Container>
    </Wrapper>
  );
};

export default Notificacoes;
