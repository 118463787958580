import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from{
    opacity:0;
    transform:translateY(-30px);
  }

  to{
    opacity:1;
    transform:translateY(0);
  }
`;

export const BurgerMenuContainer = styled.div`
  cursor: pointer;
  position: relative;
  width: 24px;
  height: 16px;
  align-items: center;

  span {
    background: #39007f;
    width: 24px;
    height: 2px;
    position: absolute;
    transition: 0.2s;
  }

  span:last-child {
    bottom: 0;
  }
  span:nth-child(2),
  span:nth-child(3) {
    bottom: 50%;
  }
  span:first-child {
    bottom: 100%;
  }

  &.open {
    span:first-child {
      transform: scale(0) rotate(45deg);
      bottom: 50%;
    }
    span:last-child {
      transform: scale(0) rotate(-45deg);
      bottom: 50%;
    }

    span:nth-child(2) {
      transform: rotate(45deg);
    }
    span:nth-child(3) {
      transform: rotate(-45deg);
    }
  }
`;

export const MenuMobileContainer = styled.div`
  height: 100%;
  align-items: center;
  position: relative;
  display: none;

  @media (max-width: 920px) {
    display: flex;
  }
`;

export const MenuWrapper = styled.div`
  position: fixed;
  top: 64px;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 10;

  padding: 0 32px;

  animation: ${fadeIn} 0.2s linear;
  transition: background 0.15s;
  background: ${({ theme }) => theme.background};

  a:not(.button) {
    margin: 25px 0;
    display: inline-block;
    font-size: 20px;
    font-weight: 600;
  }

  hr {
    border: none;
    background: #dddddd;
    height: 1px;
  }

  a.button {
    margin: 0 auto;
    margin-top: 50px;

    width: 100%;
    max-width: 650px;

    font-size: 24px;
    font-weight: 500;
    color: white;

    height: 61px;

    background: #6d0fc6;
    border-radius: 8px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;

    transition: 0.2s linear;

    :hover {
      background: #5000a2;
    }
  }

  @media (max-width: 500px) {
    padding: 0 20px;
  }
`;
