import styled from 'styled-components';
import { colors } from '@/styles/GlobalStyle';

export const Wrapper = styled.section`
  width: 100%;
  max-width: 100vw;
  background: ${({ theme }) => theme.index.notificacoes};
  transition: background 0.15s linear;

  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1440px;
  min-height: 672px;

  display: flex;
  justify-content: center;
  position: relative;

  @media (max-width: 1440px) {
    justify-content: flex-end;
  }

  @media (max-width: 1230px) {
    column-gap: 50px;
  }

  @media (max-width: 1100px) {
    flex-direction: column-reverse;
    align-items: center;
  }

  @media (max-width: 715px) {
    align-items: flex-start;
  }
`;

export const SectionWrapper = styled.div`
  width: 100%;
  max-width: 1024px;
  display: flex;

  @media (max-width: 1440px) {
    max-width: 512px;
  }

  @media (max-width: 1100px) {
    max-width: 720px;
  }
`;

export const Left = styled.div`
  width: 100%;
  max-width: 512px;

  padding-top: 80px;

  span {
    font-size: 14px;
    font-weight: 600;
    vertical-align: middle;
    text-transform: uppercase;
    display: inline-block;
    margin-left: 15px;
  }

  img {
    vertical-align: middle;
  }

  h2 {
    max-width: 320px;
    font-size: 32px;
    font-weight: 800;
    line-height: 40px;
    margin-top: 17px;
  }

  p {
    margin-top: 17px;
    font-size: 16px;
    line-height: 24px;
    max-width: 350px;
  }

  a {
    color: ${colors.red};
    margin-top: 17px;
    gap: 10px;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    transition: 0.2s linear;
    display: flex;
    width: fit-content;

    :hover {
      color: #c92255;
      box-shadow: 0px 1px #c92255;
    }
  }

  @media (max-width: 1275px) {
    padding-left: 32px;
  }

  @media (max-width: 1100px) {
    padding: 20px 0;
    padding-bottom: 40px;
    max-width: 720px;
  }

  @media (max-width: 780px) {
    padding: 20px 32px;
    padding-bottom: 40px;
  }

  @media (max-width: 500px) {
    padding: 20px;
    padding-bottom: 40px;
  }
`;

export const Right = styled.div`
  position: absolute;
  height: 672px;
  right: 0;

  flex: none;

  * {
    height: inherit !important;
  }

  @media (max-width: 1440px) {
    position: static;
  }

  @media (max-width: 715px) {
    height: initial;
  }
`;
