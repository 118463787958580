import Link from 'next/link';
import Image from 'next/image';

import {
  HeaderWrapper,
  HeaderContainer,
  HeaderLeft,
  HeaderRight,
  HeaderBackground,
} from './styles';

import { ImWhatsapp } from 'react-icons/im';
import MenuMobile from './MenuMobile';

import { useTheme } from '@/components/global/Providers/Theme';
import { useHeaderMobile } from '@/components/global/Providers/MobileHeader';
import { useSettings } from '../Providers/Footer';

const Header: React.FC = () => {
  const { currentTheme } = useTheme();
  const { isOpen } = useHeaderMobile();
  const { settings } = useSettings();
  // const headerRef = useRef(null);

  // const [scrollPosition, setScrollPosition] = useState(0);
  // const handleScroll = () => {
  //   const position = window.pageYOffset;
  //   setScrollPosition(position);
  // };

  // useEffect(() => {
  //   if (typeof window !== 'undefined') {
  //     window.addEventListener('scroll', handleScroll, { passive: true });

  //     return () => {
  //       window.removeEventListener('scroll', handleScroll);
  //     };
  //   }
  // }, []);

  // useEffect(() => {
  //   console.log((headerRef?.current as any)?.offsetTop);
  // }, [headerRef.current]);

  // useEffect(() => {
  //   if (typeof window !== 'undefined') {
  //     console.log(window.innerHeight);
  //     console.log(scrollPosition < window.innerHeight);
  //   }
  // }, [scrollPosition]);

  return (
    <HeaderBackground>
      <HeaderWrapper isMenuMobileOpen={isOpen}>
        <HeaderContainer>
          <HeaderLeft>
            <Link href="/" passHref>
              <a>
                <Image
                  src={
                    currentTheme === 'light'
                      ? '/header/logo-light.svg'
                      : '/header/logo-dark.svg'
                  }
                  width="120"
                  height="29.539"
                  alt="Logo da Hyerdev"
                />
              </a>
            </Link>
            <Link href="/chatbots" passHref>
              <a>Chatbots</a>
            </Link>
            <Link href="/notificacoes" passHref>
              <a>Notificações</a>
            </Link>
            <Link href="/aplicativos" passHref>
              <a>Aplicativos</a>
            </Link>
            <Link href="/sites" passHref>
              <a>Sites</a>
            </Link>
          </HeaderLeft>
          <HeaderRight>
            <Link href="/a-empresa" passHref>
              <a>A empresa</a>
            </Link>
            <MenuMobile />
            <a
              href={settings.whatsapp}
              target="_blank"
              rel="noreferrer noopener"
            >
              Fale com a gente <ImWhatsapp size={16} />
            </a>
          </HeaderRight>
        </HeaderContainer>
      </HeaderWrapper>
    </HeaderBackground>
  );
};

export default Header;
