import CountUp from 'react-countup';

import { Container, CircleContainer } from './styles';

interface IProps {
  number: number;
  delay?: number;
  duration?: number;
  title: string;
}

const ProgressCircle: React.FC<IProps> = ({
  number,
  delay = 0,
  duration = 0.5,
  title,
}) => {
  const totalRadius = 346;
  const maxRadius = totalRadius - (totalRadius * number) / 100;

  return (
    <Container>
      <CircleContainer maxRadius={maxRadius} delay={delay} duration={duration}>
        <svg height="71" width="71">
          <circle
            cx="35"
            cy="35"
            r="32"
            stroke="#29D3A6"
            strokeWidth="7"
            fill="transparent"
          />
        </svg>
        <CountUp end={number} start={0} duration={duration} delay={delay} />
      </CircleContainer>
      <h3>{title}</h3>
    </Container>
  );
};

export default ProgressCircle;
