import { useState } from 'react';
import VisibiltySensor from 'react-visibility-sensor';

import ProgressCircle from './ProgressCircle';

import { PerformanceContainer, CirclesContainer } from './styles';

const Performance: React.FC = () => {
  const [isVisible, setVisible] = useState(false);

  const onVisibilityChange = (event: boolean) => {
    if (isVisible) return;

    return setVisible(event);
  };

  return (
    <PerformanceContainer>
      {' '}
      <VisibiltySensor
        onChange={onVisibilityChange}
        minTopValue={150}
        scrollDelay={500}
        partialVisibility
      >
        <CirclesContainer>
          {isVisible && <ProgressCircle number={100} title="Performance" />}
          {isVisible && (
            <ProgressCircle number={93} duration={0.7} title="Acessibilidade" />
          )}
          {isVisible && (
            <ProgressCircle
              number={100}
              duration={0.9}
              title="Melhores práticas"
            />
          )}
          {isVisible && (
            <ProgressCircle number={96} duration={1.1} title="SEO" />
          )}
        </CirclesContainer>
      </VisibiltySensor>
    </PerformanceContainer>
  );
};

export default Performance;
