import { colors } from '@/styles/GlobalStyle';
import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  max-width: 1024px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
  row-gap: 40px;

  margin-top: 60px;

  @media (max-width: 950px) {
    flex-direction: column;
    margin-top: 32px;
  }
`;

export const Left = styled.div`
  width: 100%;
  max-width: 432px;

  h1 {
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
  }

  p {
    margin-top: 16px;
    font-size: 16px;
    line-height: 24px;
  }

  a {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    max-width: 262px;
    gap: 10px;
    background: ${colors.purple400};
    margin-top: 20px;
    border-radius: 12px;
    cursor: pointer;
    transition: 0.1s linear;

    @media (max-width: 950px) {
      max-width: initial;
    }

    :hover {
      background: #5000a2;
    }
  }

  @media (max-width: 650px) {
    h1 {
      font-size: 32px;
      line-height: 40px;
    }
  }

  @media (max-width: 500px) {
    p {
      max-width: 368px;
    }
  }

  @media (max-width: 375px) {
    h1 {
      font-size: 24px;
      line-height: 32px;
    }
  }
`;

export const Right = styled.div`
  position: relative;

  &.mobile {
    display: none !important;
  }

  img {
    object-fit: cover;
  }

  @media (max-width: 950px) {
    &.mobile {
      display: block !important;
    }

    &.desktop {
      display: none !important;
    }
  }
`;
