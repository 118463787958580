import Image from 'next/image';
import { Container, Left, Right } from './styles';

import HyerbotDesktopLight from '@/images/Pages/Index/hyerbot-desktop-light.png';
import HyerbotMobileLight from '@/images/Pages/Index/hyerbot-mobile-light.png';
import HyerbotDesktopDark from '@/images/Pages/Index/hyerbot-desktop-dark.png';
import HyerbotMobileDark from '@/images/Pages/Index/hyerbot-mobile-dark.png';

import { useTheme } from '@/components/global/Providers/Theme';

import { FaWhatsapp } from 'react-icons/fa';
import { useSettings } from '@/components/global/Providers/Footer';

const HeroSection: React.FC = () => {
  const { currentTheme } = useTheme();
  const { settings } = useSettings();

  return (
    <Container>
      <Left>
        <h1>Colocamos sua transportadora quilômetros à frente</h1>
        <p>
          Utilizamos as mais novas tecnologias para entregar modernização e
          eficiência nos seus processos e na comunicação com seus clientes.
        </p>
        <a href={settings.whatsapp} target="_blank" rel="noreferrer noopener">
          Fale com um especialista <FaWhatsapp size={22} />
        </a>
      </Left>
      <Right className="desktop">
        <Image
          src={
            currentTheme === 'light' ? HyerbotDesktopLight : HyerbotDesktopDark
          }
          placeholder="blur"
          quality={100}
          alt="Imagem do HyBot rodeado de aplicativos"
        />
      </Right>
      <Right className="mobile">
        <Image
          src={
            currentTheme === 'light' ? HyerbotMobileLight : HyerbotMobileDark
          }
          placeholder="blur"
          quality={100}
          alt="Imagem do HyBot rodeado de aplicativos"
        />
      </Right>
    </Container>
  );
};

export default HeroSection;
