import Image from 'next/image';
import Link from 'next/link';

import { Wrapper, Container, Left, Right, SectionWrapper } from './styles';

import SitesImages from '@/images/Pages/Index/sites-section.png';

import { HiOutlineArrowRight } from 'react-icons/hi';
import Performance from './Performance';

const Sites: React.FC = () => {
  return (
    <Wrapper>
      <Container>
        <SectionWrapper>
          <Left>
            <div>
              <img src="/icons/sites-icon.svg" alt="Ícone de um robô" />
              <span>Sites</span>
            </div>
            <h2>Velocidade e modernidade vão definir seu novo site!</h2>
            <p>
              As mais novas tecnologias de desenvolvimento web serão empregadas
              na construção de um site incrível para a sua transportadora! Sem
              deixar de lado o visual agradável e amigável ao seu cliente.
            </p>
            <Link href="/sites">
              <a>
                Conheça nosso jeito de desenvolver{' '}
                <HiOutlineArrowRight size={24} />
              </a>
            </Link>
          </Left>
        </SectionWrapper>
        <Right>
          <Image
            src={SitesImages}
            quality={100}
            alt="Imagem de um site muito bem avaliado feito pela Hyerdev"
            placeholder="blur"
          />
          <Performance />
        </Right>
      </Container>
    </Wrapper>
  );
};

export default Sites;
