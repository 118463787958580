import styled, { css } from 'styled-components';

const isClosed = css`
  /* slightly transparent fallback */

  background-color: ${({ theme }) => theme.header.background}cc;

  /* if backdrop support: very transparent and blurred */
  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background-color: ${({ theme }) => theme.header.background}cc;
    -webkit-backdrop-filter: blur(30px) opacity(95%);
    backdrop-filter: blur(30px) opacity(95%);
  }
`;

export const HeaderBackground = styled.div`
  width: 100%;
  max-width: 100vw;

  background-color: ${({ theme }) => theme.background};
  transition: background 0.15s linear;
  min-height: 64px;
  position: absolute;
  top: 0;
`;

export const HeaderWrapper = styled.header<{ isMenuMobileOpen?: boolean }>`
  width: 100%;
  max-width: 100vw;

  display: flex;
  justify-content: center;

  min-height: 64px;
  position: fixed;
  z-index: 100;

  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  transition: background-color 0.15s linear;

  ${({ isMenuMobileOpen, theme }) =>
    isMenuMobileOpen ? `background: ${theme.header.background}` : isClosed}
`;

export const HeaderContainer = styled.div`
  width: 100%;
  max-width: 1024px;

  display: flex;
  justify-content: space-between;

  transition: 0.2s;

  @media (max-width: 1088px) {
    margin: 0 32px;
  }

  @media (max-width: 500px) {
    margin: 0 20px;
  }
`;

export const HeaderLeft = styled.div`
  display: flex;
  gap: 32px;
  align-items: center;
  width: 100%;
  max-width: 700px;

  > a {
    display: flex;

    color: ${({ theme }) => theme.header.text};
    transition: 0.2s linear;
    transition: 0.2s linear;
    font-size: 14px;
    font-weight: 500;

    position: relative;

    :hover {
      color: #8623d5;
      /* :not(:first-child)::after {
        display: block;
      } */
    }

    :first-child {
      margin-right: 8px;
    }

    /* :not(:first-child)::after {
      content: '';
      width: 40%;
      height: 2px;
      background: #8623d5;
      position: absolute;
      top: 100%;
      left: 30%;
      margin-top: 3px;

      display: none;
    } */
  }

  @media (max-width: 920px) {
    > a:not(:first-child) {
      display: none;
    }
  }
`;

export const HeaderRight = styled.div`
  display: flex;
  gap: 25px;
  align-items: center;
  width: 100%;
  max-width: 300px;
  justify-content: flex-end;

  > a {
    display: flex;
    position: relative;
    color: ${({ theme }) => theme.header.text};
    transition: 0.2s linear;
    font-size: 14px;
    font-weight: 500;

    @media (max-width: 920px) {
      display: none;
    }

    :hover {
      color: #8623d5;

      /* :first-child::after {
        display: block;
      } */
    }

    :first-child {
      margin-right: 8px;
    }

    :last-child {
      height: 33px;
      width: 100%;
      max-width: 185px;
      background: ${({ theme }) => theme.header.button.background};
      color: ${({ theme }) => theme.header.button.text};
      justify-content: center;
      align-items: center;
      border-radius: 8px;

      gap: 6px;

      :hover {
        background: ${({ theme }) => theme.header.button.hover};
      }
    }

    /* :first-child::after {
      content: '';
      width: 40%;
      height: 2px;
      background: #8623d5;
      position: absolute;
      top: 100%;
      left: 30%;
      margin-top: 3px;

      display: none;
    } */
  }
`;
