import Image from 'next/image';
import Link from 'next/link';

import { Wrapper, Container, Right, Left, SectionWrapper } from './styles';

import EspecialidadeSection from '@/images/Pages/Index/especialidade-section.png';

import { HiOutlineArrowRight } from 'react-icons/hi';

const Especialidade: React.FC = () => {
  return (
    <Wrapper>
      <Container>
        <Left>
          <Image
            src={EspecialidadeSection}
            quality={100}
            alt="Imagem de pessoas trabalhando em conjunto num computador"
            placeholder="blur"
          />
        </Left>
        <SectionWrapper>
          <Right>
            <div>
              <h2>Nosso diferencial é que conhecemos bem nosso cliente!</h2>
              <p>
                Desde 2018 estamos ouvindo dezenas de transportadoras sobre suas
                rotinas e obstáculos no atendimento ao cliente. Conhecemos bem o
                mercado e estamos prontos para te entregar soluções
                especializadas!
              </p>
              <Link href="/a-empresa">
                <a>
                  Conheça a Hyerdev <HiOutlineArrowRight size={24} />
                </a>
              </Link>
            </div>
          </Right>
        </SectionWrapper>
      </Container>
    </Wrapper>
  );
};

export default Especialidade;
